import { useMutation, gql } from '@apollo/client';
import { toast } from 'react-toastify';

import { ReactComponent as CheckArrowIcon } from '../../../assets/icons/CheckArrowIcon.svg';
import { TabTitle } from "../../../utils/TabTitle";
import { useForm } from '../../../hooks/useForm';
import RequestLoginBanner from "../../../components/RequestLoginBanner/RequestLoginBanner";
import ForgotCredentials from "../../../components/ForgotCredentials/ForgotCredentials";
import './RequestLogin.scss';

function RequestLogin({title}) {
  TabTitle(title  || 'KFC LRM');

  const { onChange, onSubmit, values } = useForm(requestLoginCallback, {
    firstName: '',
    lastName: '',
    password: '',
    email: ''
  });

  const [requestLogin, { loading }] = useMutation(REQUEST_LOGIN, {
    update(_, { data: { createRequestLogin: userData } }) {
      if (userData) {
        toast.success('Success', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "toast-success"
        });
        toast.info('Your request will be processed within 24 hours', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "toast-success"
        })
      }
    },
    onError(err) {
      console.log(err)
    },
    variables: values
  });

  function requestLoginCallback() {
    requestLogin();
  }

  let changeFocusIvent = (e) => {
    e.target.removeAttribute('readonly')
  }

  let passwordView = (e) => {
    e.preventDefault();
    let passwordField = document.querySelector('#password')
    passwordField.type === "password" ? passwordField.type = "text" : passwordField.type = "password";
    e.target.classList.toggle('active');
  };

  return (
    <div className={'request-login'}>
      <form onSubmit={onSubmit} className={'request-login__form'}>
        <RequestLoginBanner />
        <h3 style={{marginBottom: '21px'}}>Request Login</h3>
        <div className='request-login__form__row'>
          <div className='request-login__form__col'>
            <input type={'text'} id={'firstName'} name={'firstName'} placeholder={'First Names'} value={values.firstName} onChange={onChange} />
            <input type={'text'} id={'lastName'} name={'lastName'} placeholder={'Family Name'} value={values.lastName} onChange={onChange} />
          </div>
          <div className='request-login__form__col'>
            <div className='request-login__check'>
              <input type={'email'} id={'email'} name={'email'} placeholder={'Add Email'} value={values.email} onChange={onChange} />
              <CheckArrowIcon />
            </div>
            <div className={'password-view'}>
              <input type={'password'} id={'password'} name={'password'} placeholder={'Add Password'} value={values.password} autoComplete={'new-password'} onChange={onChange} readOnly onFocus={changeFocusIvent} />
              <button onClick={passwordView} />
            </div>
          </div>
        </div>
        <button type={'submit'}>Send</button>
        <ForgotCredentials />
      </form>
    </div>
  )
}


const REQUEST_LOGIN = gql`
  mutation createRequestLogin(
    $firstName: String!
    $lastName: String!
    $password: String!
    $email: String!
  ) {
    createRequestLogin(
      requestLoginInput: {
        firstName: $firstName
        lastName: $lastName
        password: $password
        email: $email
      }
    ) {
      firstName
      lastName
      email
    }
  }
`;

export default RequestLogin