import { useState, useEffect, useContext } from 'react';
import Select, { components } from 'react-select';

import { TabTitle } from '../../utils/TabTitle';
import FilesWidget from '../../components/Widgets/FilesWidget/FilesWidget';
import ScreenCountWidget from '../../components/Widgets/ScreenCountWidget/ScreenCountWidget';
import PromosWidget from '../../components/Widgets/PromosWidget/PromosWidget';
import PromtionWidget from '../../components/Widgets/PromtionWidget/PromtionWidget';
import { AuthContext } from '../../context/auth';
import LSM from '../LSM/LSM';
import './Dashboard.scss';
import TestMarket from '../Pods/TestMarket';
import { USER_TYPE } from '../../defs/User';


function Dashboard(props) {
    const { user } = useContext(AuthContext);

    if (!user)
    {
        console.log('no user!',user);
    }

    const [options, setOptions] = useState([]);
    const [data, setData] = useState([
        {
            value: '',
            label: '',
            fileWidget: [],
            ScreenCountWidget: [],
            PromosWidget: [],
            PromtionWidget: []
        },
    ])

    TabTitle(props.title || 'KFC LRM');

    // useEffect(() => {
    //     fetch('./Dashboard.json')
    //         .then((response) => response.json())
    //         .then((data) => {
    //             setData(data);
    //             setOptions(data);
    //         })
    //         .catch((error) => {
    //             console.error('Error:', error);
    //         });
    // }, []);

    let changePodValue = (e) => {
        let filterValue = e.value;
        setData(options.filter(item => item.value === filterValue))
    }

    const style = {
        container: base => ({
            ...base,
            width: '100%',
            zIndex: 2
        }),
        menu: (provided, state) => ({
            ...provided,
            width: '100%',
            maxWidth: '57%',
            padding: 0,
            marginBottom: 0,
            marginTop: '0',
            boxShadow: 'none',
            border: '1px solid #EEEEEE',
            borderTop: '0',
            fontWeight: '600',
            fontSize: '16px',
            transition: 'all .2s ease-in-out',
            borderRadius: '0',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        }),
        menuList: (provided, state) => ({
            ...provided,
            padding: '0',
            borderRadius: '0',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        }),
        option: (provided, state) => ({
            ...provided,
            color: 'black',
            backgroundColor: '#fff',
            padding: '0 20px',
            cursor: 'pointer',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            transition: 'all .2s ease-in-out',
            '&:hover': {
                color: '#fff',
                backgroundColor: '#F40027'
            }
        }),
        control: (base, state) => ({
            ...base,
            padding: '0 20px',
            border: '1px solid #EEEEEE',
            borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
            boxShadow: '0 !important',
            cursor: 'pointer',
            width: '100%',
            maxWidth: '57%',
            height: '60px',
            borderRadius: '10px',
            borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '10px',
            borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '10px',
            fontWeight: '600',
            fontSize: '16px',
            transition: '.2s easy-in-out',
            '&:hover': {
                borderColor: '1px solid #EEEEEE !important',
                borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
            }
        }),
        valueContainer: base => ({
            ...base,
            padding: '0',
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: '0'
        })
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
                </svg>
            </components.DropdownIndicator>
        );
    };

    if (user.userRole === USER_TYPE.ADMIN || user.userRole === USER_TYPE.MARKETER) {


        
        return (
            <div className={'dashboard'}>
                <div className={'dashboard__bar'}>
                    <Select
                        defaultValue={{ "value": "pod-01", "label": "POD 01 - 27th December 2022 / 23rd January 2023" }}
                        options={options}
                        styles={style}
                        isSearchable={false}
                        onChange={changePodValue}
                        components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: DropdownIndicator
                        }}
                    />
                    <TestMarket />
                </div>
                <div className={'dashboard__content'}>
                    <div className={'col'}>
                        <FilesWidget title={data[0].value} data={data[0].fileWidget} />
                        <ScreenCountWidget data={data[0].ScreenCountWidget} />
                    </div>
                    <div className={'col'}>
                        <PromosWidget data={data[0].PromosWidget} />
                    </div>
                    <div className={'col'}>
                        <PromtionWidget data={data[0].PromtionWidget} />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <LSM />
        )
    }
}

export default Dashboard