import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './FileUpload.scss';
import previewFile from './preview-file.png';

const UploadSlider = ({ type }) => {
    const [hiRes, setHiRes] = useState('');
    const [cardName, setCardName] = useState('');
    const [cardLink, setCardLink] = useState('');

    let addFileName = e => {
        setCardName(e.target.value)
    }

    let addLink = e => {
        setCardLink(e.target.value)
    }

    let addLowRes = e => {
        setHiRes(e.target.files[0]);
        e.target.parentElement.querySelector('label').innerText = e.target.files[0].name;
        let file = e.target.files[0];

        let images = document.createElement('img');
        images.src = e.target.files[0];
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (e) {
            // The file reader gives us an ArrayBuffer:
            let buffer = e.target.result;

            // We have to convert the buffer to a blob:
            let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'image/png' });

            // The blob gives us a URL to the video file:
            let url = window.URL.createObjectURL(videoBlob);

            images.src = url;
        }

        let container = document.querySelector('.file-preview');
        container.innerHTML = '';
        container.appendChild(images);
    }

    const notifyError = () => toast.error('Fill in the required fields', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    let notifySuccess = () => toast.success('Success', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });



    const onSubmit = async e => {
        e.preventDefault();

        if (cardName && hiRes) {
            const formData = new FormData();
            formData.append('fileHiRes', hiRes);
            formData.append('cardName', cardName)
            formData.append('cardLink', cardLink)
            formData.append('fileType', hiRes.type);

            try {
                const res = await axios.post(type === 'slider' ? process.env.REACT_APP_API_SERVER + 'upload-lsm-slide' : process.env.REACT_APP_API_SERVER + 'upload-lrm-cards', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });


            } catch (err) {
                console.log(err)
            }
            notifySuccess();
        } else {
            notifyError();
        }
    };

    return (
        <>
            <div className='file-container'>
                <div className='file-preview'>
                    <img src={previewFile} alt={'preview'} />
                </div>
                <form onSubmit={onSubmit}>
                    <div className='file-upload'>
                        <h5>Upload Files</h5>
                        <p>You can upload one or several files.</p>
                        <hr />
                        <div className='file-upload__file-name'>
                            <h6>Add a card name</h6>
                            <input onChange={addFileName} type="text" placeholder='File name...' />
                        </div>
                        <div className='file-upload__file-name'>
                            <h6>Add a slide link</h6>
                            <input onChange={addLink} type="text" placeholder='Slide link...' />
                        </div>
                        <div className='file-upload__low-res'>
                            <h6>Add a file</h6>
                            <div className='file-input'>
                                <input type='file' onChange={addLowRes} />
                                <label className='label' data-js-label>No file selected</label>
                            </div>
                        </div>
                        <button type='submit' className='upload-btn'>Upload</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default UploadSlider;