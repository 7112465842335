import React, { useState, useEffect, useContext, useRef } from 'react';
// import { Document, Page, pdfjs } from "react-pdf";
// import pdfjsWorker from "react-pdf/node_modules/pdfjs-dist/build/pdf.worker.entry";
import './style.scss';
import { AuthContext } from '../../../context/auth';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { EDITOR_TYPE } from '../../../defs/Editor';
import AssetPopup from '../../Popup/AssetPopup';
import Popup from '../../Popup/Popup';
import CouponEditor from '../../ContentEditors/Coupon/CouponEditor';
import { Buffer } from 'buffer';
import styled from '@emotion/styled';
import Placeholder, { PlaceholderIcon } from './Placeholder';
import File from '../../../utils/File';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import 'file-icon-vectors/dist/file-icon-vivid.min.css'

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const KeyCodes = {
    comma: 188,
    space: 32,
    enter: 13,
    tab: 9
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space, KeyCodes.tab];



export default function LSMCard({
    name,
    asset,
    safeName,
    assetId,
    hiResUrl,
    placeholder,
    editorType,
    enablePopup,
    enableTags,
    enableEmailFile,
    enableEmailPrintQuote,
    enableHires,
    enableSave,
    enableReplace,
    enableDelete,
    enableEditName,
    tags,
    onDelete
}) {
    const [showAssetPopup, setShowAssetPopup] = useState(false);
    const [showCouponEditor, setShowCouponEditor] = useState(false);

    // const [storeNameOptions, setStoreNameOptions] = useState(false);
    const [PDFRender1, setPDFRender1] = useState(false);
    const [PDFRender2, setPDFRender2] = useState(false);
    const { user } = useContext(AuthContext);
    const navigateTo = useNavigate();
    const location = useLocation();

    //Check if this asset should already show its popup.
    useEffect(() => {
        const selectedAsset = location.pathname.split('/asset/')[1];
        if (selectedAsset && selectedAsset.split('/').pop() == safeName)
        {
            switch (editorType)
            {
                case EDITOR_TYPE.COUPON: {
                    setShowCouponEditor(true);
                    break;
                }
                default: {
                    setShowAssetPopup(true);
                }
            }
        }
    },[location]);

    const canvasRef = useRef(null);
    let onRenderSuccess = () => {};
    let onRenderTextLayerSuccess = () => {};

    if (!placeholder) {
        
        onRenderSuccess = () => {
            setPDFRender1(true);
            onRenderComplete();
        }

        onRenderTextLayerSuccess = () => {
            setPDFRender2(true);
            onRenderComplete();
        }

        const onRenderComplete = async () => {
            if (!PDFRender1 || !PDFRender2)
            {
                return;
            }
            const imgData = canvasRef.current.toDataURL("image/jpeg");
            const base64Image = imgData.split(';base64,').pop();
            const buffer = Buffer.from(base64Image, 'base64');
            const file = new File([buffer], "thumbnail.jpeg", { type: "image/jpeg" });

            const formData = new FormData();
            formData.append("placeholder", file);
            formData.append("assetId", asset.id);

            try {
                await fetch(process.env.REACT_APP_API_SERVER + 'upload-asset-thumbnail', {
                    method: 'POST',
                    body: formData
                });

                console.log('Success - Uploaded new Thumbnail');
            } catch (e) {
                console.log(e);
            }
        };
    }

    const onCardClick = () => {
        // if (location.pathname.match(/\/asset\//))
        // {
        //     let baseURI = location.pathname.split(/\/asset\//)[0];
        //     navigateTo(`${baseURI}/asset/${safeName}`);
        // }
        // else
        // {
        //     navigateTo(`${location.pathname}/asset/${safeName}`);
        // }
        navigateTo(asset.uri);
    }

    const onClosePopup = () => {
        let baseURI = location.pathname.split(/\/asset\//)[0];
        navigateTo(`${baseURI}`);
    }

    return (
        <>
            <div className='lrm-card' onClick={onCardClick}>
                <div className="lrm-card__shadow" />
                <div className='lrm-card__header'>
                    {(() => {
                        if (File.isAudio(asset.fileName)) {
                            return (
                                <audio src={`/static${asset.file}`} controls />
                            );
                        }
                        else if (File.isVideo(asset.fileName)) {
                            const ext = asset.file.split('.').pop();
                            return (
                                <video controls autoPlay muted loop width="250">
                                    <source src={`/static${asset.file}`}  type={`video/${ext}`} />
                                </video>
                            );
                        }
                        else {
                            return <Placeholder asset={asset} />;
                        }
                        // else if (File.isPdf(asset.fileName)) {
                        //     return <Placeholder asset={asset} />;
                        // }
                    })()}
                </div>
                <div className='lrm-card__footer'>
                    <h3>{name.replaceAll('_', ' ')}</h3>
                </div>
            </div>



            {(enablePopup && showAssetPopup) && (
                <AssetPopup
                    asset={asset}
                    url={hiResUrl}
                    assetId={assetId}
                    trigger={showAssetPopup}
                    setTrigger={setShowAssetPopup}
                    onClose={onClosePopup}
                    enableTags={enableTags}
                    tags={tags}
                    enableDownload={enableHires}
                    enableEmailFile={enableEmailFile}
                    enableEmailPrintQuote={enableEmailPrintQuote}
                    enableReplace={enableReplace}
                    enableDelete={enableDelete}
                    enableSave={enableSave}
                    enableEditName={enableEditName}
                    onDelete={onDelete}
                >
                    <div className='lsm-popup'>
                        <div className='lsm-popup__content'>
                            <h5>{name.replaceAll('_', ' ')}</h5>
                            <div>
                                
                            </div>
                        </div>
                    </div>
                </AssetPopup>
            )}
            {(enablePopup && showCouponEditor) && (
                <Popup
                    customclassName={'popup--coupons'}
                    style={{ maxWidth: '1460px', height: '799px' }}
                    trigger={showCouponEditor}
                    onClose={onClosePopup}
                    setTrigger={setShowCouponEditor}
                >
                    <CouponEditor />
                </Popup>
            )}
        </>
    )
}