import { useContext } from 'react';
import { AuthContext } from '../../../context/auth';
import { USER_TYPE } from '../../../defs/User';
import './style.scss';

function NavList({ parent, status, children, roles, disabled }) {
    const { user } = useContext(AuthContext);
    const allowedRoles = roles || [
        USER_TYPE.ADMIN,
        USER_TYPE.MARKETER,
        USER_TYPE.BUSINESS_OWNER,
        USER_TYPE.DEVELOPER
    ];

    //Does the user have permission to view this?                                                                                                                                                                                    .8
    if (allowedRoles.includes(user.userRole))
    {
        const classes = `sidebar-${parent || 'parent'}`;
        const activeClass = status?'active':'';
        const classesList = `${classes} ${activeClass} ${disabled?'disabled':''}`;

        return (
            <nav className={classesList}>
                <ul>
                    {children}
                </ul>
            </nav>
        );
    }
    return null;
}

export default NavList;