import { ReactComponent as CheckArrowIcon } from '../../../assets/icons/CheckArrowIcon.svg';
import { TabTitle } from "../../../utils/TabTitle";
import { useForm } from '../../../hooks/useForm';
import RequestLoginBanner from "../../../components/RequestLoginBanner/RequestLoginBanner";
import ForgotCredentials from "../../../components/ForgotCredentials/ForgotCredentials";
import './Registration.scss';

function Registration({title}) {
  TabTitle(title  || 'KFC LRM');

  const { onChange, onSubmit, values } = useForm(requestLoginCallback, {
    username: '',
    lastName: '',
    password: '',
    email: ''
  });


  function requestLoginCallback() {
    // requestLogin();
  }

  let changeFocusIvent = (e) => {
    e.target.removeAttribute('readonly')
  }

  let passwordView = (e) => {
    e.preventDefault();
    let passwordField = document.querySelector('#password')
    passwordField.type === "password" ? passwordField.type = "text" : passwordField.type = "password";
    e.target.classList.toggle('active');
  };

  return (
    <div className={'registration'}>
      <form onSubmit={onSubmit} className={'registration__form'}>
        <RequestLoginBanner />
        <h3 style={{marginBottom: '21px'}}>Sign up/Register</h3>
        <div className='registration__form__row'>
          <div className='registration__form__col'>
            <input type={'text'} id={'username'} name={'username'} placeholder={'Add Username'} value={values.username} onChange={onChange} />
            <input type={'email'} id={'email'} name={'email'} placeholder={'Add Email'} value={values.email} onChange={onChange} />
          </div>
          <div className='registration__form__col'>
            <div className='registration__check'>
              <input type={'tel'} id={'phone'} name={'phone'} placeholder={'Add Mobile'} value={values.phone} onChange={onChange} />
              <CheckArrowIcon />
            </div>
            <div className={'password-view'}>
              <input type={'password'} id={'password'} name={'password'} placeholder={'Add Password'} value={values.password} onChange={onChange} readOnly onFocus={changeFocusIvent} />
              <button onClick={passwordView} />
            </div>
          </div>
        </div>
        <button type={'submit'}>Send</button>
        <ForgotCredentials />
      </form>
    </div>
  )
}

export default Registration