import { useState, useContext } from 'react';
import Select, { components } from 'react-select';
import { useQuery, gql } from '@apollo/client';
import { toast } from "react-toastify";

import { AuthContext } from '../../../context/auth';
import { TabTitle } from "../../../utils/TabTitle";
import { PageDescription } from '../../../utils/PageDescription';
import Popup from '../../../components/Popup/Popup';
import StoreSelector from '../../../components/Select/StoreSelector';
import useFetch from '../../../hooks/useFetch';
import './style.scss';

function BriefingTemplates({ title }) {
  TabTitle(title || 'KFC LRM');
  PageDescription('Send us a Brief', '');
  const { fetcher:APISendBriefingTemplate } = useFetch(`${process.env.REACT_APP_API_SERVER}briefing-template`);

  const { user } = useContext(AuthContext)
  const [userData, setUserData] = useState([])
  const [buttonPopup, setButtonPopup] = useState(false);
  const [selectStoreName, setSelectStoreName] = useState(null);
  const [selectStoreType, setSelectStoreType] = useState(null);
  const [selectAssetDeadline, setSelectAssetDeadline] = useState(null);
  const [selectBrief, setBrief] = useState(null);
  const [selectSpecifications, setSpecifications] = useState(null);

  const [checboxDataRequire, setChecboxDataRequire] = useState([
    {
      title: 'POSTER',
      check: true
    },
    {
      title: 'FLYER',
      check: false
    },
    {
      title: 'GARDEN BANNER',
      check: false
    },
    {
      title: 'BILLBOARD',
      check: false
    },
    {
      title: 'COUPON / SHOP-A-DOCKET',
      check: false
    },
    {
      title: 'DIGITAL (KRUSHER BLADE, SOCIAL, PRE-READER)',
      check: false
    },
    {
      title: 'PRESS ADVERTISEMENT',
      check: false
    },
    {
      title: 'MEDIA \u002D RADIO',
      check: false
    },
    {
      title: 'MEDIA \u002D LETTER BOX DROP',
      check: false
    }
  ])
  const [checboxDataArchive, setChecboxDataArchive] = useState([
    {
      title: 'DRIVE SALES',
      check: true
    },
    {
      title: 'DRIVE CUSTOMERS TO STORE',
      check: false
    },
    {
      title: 'DRIVE DAYPART (e.g lunch / dinner / late night)',
      check: false
    },
    {
      title: 'DRIVE MEATBLOCK / SEGMENT  (e.g. Tenders / low end)',
      check: false
    },
    {
      title: 'DRIVE KFC APP',
      check: false
    },
    {
      title: 'DRIVE DELIVERY',
      check: false
    },
    {
      title: 'NEW STORE OPENING OR RENNOVATION',
      check: false
    },
    {
      title: 'SPONSORSHIP',
      check: false
    },
    {
      title: 'YOUTH FOUNDATION',
      check: false
    },
    {
      title: 'HIRE STAFF',
      check: false
    },
    {
      title: 'STAFF INCENTIVES, RECOGNITION & REWARDS',
      check: false
    }
  ])

  const FETCH_USER_QUERY = gql`
  query($userId: ID!) {
    getUser(userId: $userId) {
      email
      firstName
      lastName
      company
      address
    }
  }
`;

  const { loading, error, data } = useQuery(FETCH_USER_QUERY,
    {
      variables: { userId: user.id || user._id },
      onCompleted: (data) => setUserData(data.getUser)
    });


  if (loading) return <div className='preloader'><div id="loader" /></div>;
  if (error) return <p>Error :</p>;

  const style = {
    container: base => ({
      ...base,
      width: '100%',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      maxWidth: '379px',
      padding: 0,
      marginBottom: 0,
      marginTop: '0',
      boxShadow: 'none',
      border: '1px solid #EEEEEE',
      borderTop: '0',
      fontWeight: '400',
      fontSize: '14px',
      transition: 'all .2s ease-in-out',
      borderRadius: '0',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      '@media (max-width: 767px)': {
        maxWidth: '100%',
      }
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0',
      borderRadius: '0',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: '#fff',
      padding: '0 10px',
      cursor: 'pointer',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#F40027'
      }
    }),
    control: (base, state) => ({
      ...base,
      padding: '0 10px',
      border: '1px solid #EEEEEE',
      borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      boxShadow: '0 !important',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '379px',
      minHeight: '36px',
      height: '36px',
      borderRadius: '4px',
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '4px',
      borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '4px',
      fontWeight: '400',
      fontSize: '14px',
      transition: '.2s easy-in-out',
      '&:hover': {
        borderColor: '1px solid #EEEEEE !important',
        borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      },
      '@media (max-width: 767px)': {
        maxWidth: '100%',
      }
    }),
    valueContainer: base => ({
      ...base,
      padding: '0',
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0'
    }),
    singleValue: base => ({
      ...base,
      margin: 0
    })
  };

  const handleChange = (data, onChange, label) => {
    onChange(data.map(item => {
      if (item.title === label) {
        item.check = !item.check
      }
      return item
    }))
  }

  const Checkbox = ({ label, checked, data, onChange }) => {
    return (
      <div className="checbox-container">
        <label className="checbox-group">{label}
          <input type="checkbox" checked={checked} onChange={() => { handleChange(data, onChange, label) }} />
          <span className="checkmark" />
        </label>
      </div>
    )
  }

  let sendBriefingTemplate = (e) => {
    e.preventDefault();

    let validationFiels = [
      document.querySelector('#firstName'),
      document.querySelector('#lastName'),
      document.querySelector('#jobTitle'),
      document.querySelector('#email'),
      document.querySelector('#company'),
      document.querySelector('#additional-info-brief'),
      document.querySelector('#additional-info-specifications')
    ]

    function validateValue(item) {
      if (item.value) {
        item.classList.remove('invalid')
      } else {
        item.classList.add('invalid');
        toast.error('Required fields', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'required fields'
        });
      }
    }


    validationFiels.forEach(item => {
      validateValue(item)
    })

    if (document.querySelector('#firstName').value
    && document.querySelector('#lastName').value
    && document.querySelector('#jobTitle').value
    && document.querySelector('#company').value
    && document.querySelector('#additional-info-brief').value
    && document.querySelector('#additional-info-specifications').value) {
      setButtonPopup(true);
    }
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const handleChangeUserData = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  };

  const typeStoreName = [
    { value: "DTO", label: "DTO" },
    { value: "FSDT", label: "FSDT" },
    { value: "Inline", label: "Inline" },
    { value: "Mail Can", label: "Mail Can" },
    { value: "Mail CUP", label: "Mail CUP" },
    { value: "Mail runs FSDT menu", label: "Mail runs FSDT menu" },
    { value: "SMALL BOX", label: "SMALL BOX" }
  ]

  const sendForm = async (e) => {
    let checkboxTouchpointsRequired = checboxDataRequire.filter((obj) => obj.check === true);
    let checkboxArchive = checboxDataArchive.filter((obj) => obj.check === true);
    let jobTitle = document.getElementById("jobTitle").value;

    let sendData = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      jobTitle: jobTitle,
      email: userData.email,
      contactNumber: userData.contactNumber,
      company: userData.company,
      address: userData.address,
      storeName: selectStoreName,
      storeType: selectStoreType,
      deadline: selectAssetDeadline,
      additionalInfoBrief: selectBrief,
      additionalInfoSpecifications: selectSpecifications,
      CheckboxTouchpointsRequired: checkboxTouchpointsRequired,
      CheckboxArchive: checkboxArchive
    };

    const sendProgress = (promise) => toast.promise(promise, {
        pending: 'Sending...',
        success: 'Complete!',
        error: 'Error!',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'lsm_upload_file'
    });

    setButtonPopup(false);
    const result = await sendProgress
    (
      APISendBriefingTemplate({
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
          },
          body: JSON.stringify(sendData)
      })
    );

    if (result) {
      toast.success('Success', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: "success-notify"
      });
    }
  }

  return (
    <div className={'briefing-templates-page'}>
      <form onSubmit={sendBriefingTemplate} className='briefing-templates'>
        <div className='row'>
          <div className='col'>
            <h3>Business Details</h3>
            <hr style={{ marginTop: 0 }} />
            <div className='details'>
              <h5>User</h5>
              <p>First Name<span>*</span></p>
              <input type="text" id={'firstName'} placeholder='First Name' name="firstName" value={userData.firstName} onChange={handleChangeUserData} />
              <p>Last Name<span>*</span></p>
              <input type="text" id={'lastName'} placeholder='Last Name' name="lastName" value={userData.lastName} onChange={handleChangeUserData} />
              <p>Job Title<span>*</span></p>
              <input type="text" id={'jobTitle'} name="jobTitle" placeholder='Job Title' value={userData.jobTitle} onChange={handleChangeUserData} />
              <p>Email Address<span>*</span></p>
              <input type="text" id="email" name="email" placeholder='Email Address' value={userData.email} onChange={handleChangeUserData} />
              <p>Contact Number<span>*</span></p>
              <input type="text" id="contactNumber" name="contactNumber" placeholder='Contact Number' value={userData.contactNumber} onChange={handleChangeUserData} />
            </div>
            <h3 style={{ marginTop: '31px' }}>Company/Business</h3>
            <hr />
            <div className='details'>
              <p>Company/Business Name<span>*</span></p>
              <input type="text" id={'company'} placeholder='Business Name Auto' name="company" value={userData.company} onChange={handleChangeUserData} />
              <p>Company/Business Address<span>*</span></p>
              <input type="text" id={'address'} placeholder='Business Address Auto' name="address" value={userData.address} onChange={handleChangeUserData} />
            </div>
            <h3 style={{ marginTop: '31px' }}>Store Details</h3>
            <p>Please check theses </p>
            <hr />
            <div className='details'>
              <p>Store name</p>
              <StoreSelector
                value={selectStoreName}
                onChange={(selected) => { setSelectStoreName(selected) }}
              />
              <p style={{ marginTop: '9px' }}>Store type</p>
              <Select
                defaultValue={{ "value": "select_store_type", "label": "Select store type" }}
                options={typeStoreName}
                styles={style}
                isSearchable={false}
                onChange={(e) => { setSelectStoreType(e.value) }}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: DropdownIndicator
                }}
              />
            </div>
            <h3 style={{ marginTop: '31px' }}>Asset Deadline</h3>
            <p>Please check theses </p>
            <hr />
            <div className='details'>
              <p>Asset Deadline</p>
              <input onChange={(e) => { setSelectAssetDeadline(e.target.value) }} type={'date'} />
            </div>
          </div>
          <div className='col'>
            <h3>Why are you writing this brief?</h3>
            <hr style={{ marginTop: 0 }} />
            <div className='details'>
              <p>Addtional info<span>*</span></p>
              <textarea id={'additional-info-brief'} onChange={(e) => { setBrief(e.target.value) }} placeholder='Write here'></textarea>
            </div>
            <h3 style={{ marginTop: '31px' }}>Specifications</h3>
            <p>(Details of size and specifications from media booking or printer)</p>
            <hr />
            <div className='details'>
              <p>Addtional info<span>*</span></p>
              <textarea id={'additional-info-specifications'} onChange={(e) => { setSpecifications(e.target.value) }} placeholder='Write here'></textarea>
            </div>
            <h3 style={{ marginTop: '31px' }}>Touchpoints Required </h3>
            <hr />
            <div className='details details-list'>
              {
                checboxDataRequire.map((element, index) => (
                  <Checkbox
                    key={index}
                    checked={element.check}
                    label={element.title}
                    data={checboxDataRequire}
                    onChange={setChecboxDataRequire}
                  />
                ))
              }
            </div>
          </div>
          <div className='col'>
            <h3>What are you trying to achieve?</h3>
            <hr style={{ marginTop: 0 }} />
            <div className='details details-list'>
              {
                checboxDataArchive.map((element, index) => (
                  <Checkbox
                    key={index}
                    checked={element.check}
                    label={element.title}
                    data={checboxDataArchive}
                    onChange={setChecboxDataArchive}
                  />
                ))
              }
            </div>

            <div style={{ marginTop: '63px' }} className='btn-group'>
              <button className='btn-submit'>Submit</button>
            </div>
            <Popup style={{ maxWidth: '400px', height: '323px' }} closeBtn={false} trigger={buttonPopup} setTrigger={setButtonPopup}>
              <div className='popup__success'>
                <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M36.5 0C16.4242 0 0 16.4242 0 36.5C0 56.5757 16.4242 73 36.5 73C56.5757 73 73 56.5757 73 36.5C73 16.4242 56.5757 0 36.5 0V0ZM15.6421 26.0736C15.6421 23.2042 17.9885 20.8579 20.8579 20.8579C23.7273 20.8579 26.0736 23.2042 26.0736 26.0736C26.0736 28.943 23.7273 31.2893 20.8579 31.2893C17.9885 31.2844 15.6421 28.9381 15.6421 26.0736ZM53.9654 52.4012C49.2727 57.0938 42.7569 59.6992 36.5 59.6992C30.2431 59.6992 23.7273 57.3529 19.0346 52.4012C17.9934 51.36 17.9934 49.7958 19.0346 48.7497C20.0758 47.7085 21.64 47.7085 22.6861 48.7497C30.2481 56.3117 42.7619 56.3117 50.3189 48.7497C51.3601 47.7085 52.9242 47.7085 53.9704 48.7497C55.0116 49.7957 55.0116 51.3599 53.9655 52.4012H53.9654ZM52.1421 31.2843C49.2727 31.2843 46.9264 28.938 46.9264 26.0686C46.9264 23.1992 49.2727 20.8528 52.1421 20.8528C55.0115 20.8528 57.3579 23.1992 57.3579 26.0686C57.3579 28.938 55.0115 31.2843 52.1421 31.2843V31.2843Z" fill="#FFB30F" />
                </svg>
                <h3>Briefing Templates</h3>
                <p>Are you sure you want to submit the form?</p>
                <div className='btn-group'>
                  <button onClick={sendForm} className='btn-save'>CONFIRM</button>
                  <button onClick={(e) => { e.preventDefault(); setButtonPopup(false) }} className='btn-cancel'>CANCEL</button>
                </div>
              </div>
            </Popup>
          </div>
        </div>
      </form>
    </div>
  )
}

export default BriefingTemplates