import { gql } from '@apollo/client';


export const GET_ALL_CATEGORIES = gql`
query GetCategories {
    getCategories {
      id
      parentId
      name
      uri
      description
      weight
      permissions
      icon
      createdAt
      status
    }
  }
`;

export const GET_CATEGORY_BY_URI = gql`
query GetCategoryByURI($uri: String!) {
    getCategoryByURI(URI: $uri) {
      id
      parentId
      name
      uri
      description
      weight
      permissions
      icon
      createdAt
      status
    }
  }
`;

export const GET_CATEGORIES_AND_ASSETS_BY_URI = gql`
query GetCategoryAndAssetsByURI($uri: String!, $uriEnd: String!) {
  getCategoryByURI(URI: $uriEnd) {
    id
    parentId
    name
    uri
    description
    weight
    permissions
    icon
    createdAt
    status
  },
  getAssetsByCategoryURI(URI: $uri) {
    id
    name
    fileName
    safeName
    category
    tags
    file
    editorType
    uri
    pinned
    weight
    data
    permissions
    createdAt
  }
}
`;

export const CREATE_CATEGORY = gql`
mutation UpdateCategory($updateCategoryInput: UpdateCategoryInput) {
  updateCategory(updateCategoryInput: $updateCategoryInput) {
    parentId
    name
    uri
    description
    weight
    permissions
    status
  }
}
`;

export const UPDATE_CATEGORY = gql`
mutation UpdateCategory($updateCategoryInput: UpdateCategoryInput) {
  updateCategory(updateCategoryInput: $updateCategoryInput) {
    id
    parentId
    name
    uri
    description
    weight
    permissions
    icon
    status
  }
}
`;

const categoryFields = `
id
parentId
name
uri
description
weight
permissions
createdAt
icon
status
`;

export const GET_CATEGORY_TREE = gql`
fragment CategoryFields on Category {
  ${categoryFields}
  children {
    ${categoryFields}
    children {
      ${categoryFields}
      children {
        ${categoryFields}
        children {
          ${categoryFields}
        }
      }
    }
  }
}

query getCategoryTree($parentId: ID) {
  getCategoryTree(parentId: $parentId) {
    ...CategoryFields
  }
}
`;