import { useState, useEffect, useContext, useMemo, lazy, Suspense } from 'react';
import { AuthContext } from '../../context/auth';
import NavList from './NavList/NavList';
import NavItem from './NavItem/NavItem';
import { ReactComponent as DashboardIcon } from './icons/DashboardIcon.svg';
import { ReactComponent as SearchIcon } from './icons/SearchIcon.svg';
import { ReactComponent as PodsIcon } from './icons/PodsIcon.svg';
import { ReactComponent as LSMIcon } from './icons/LSMIcon.svg';
import { ReactComponent as AddEditStoresIcon } from './icons/AddEditStoresIcon.svg';
import { ReactComponent as SettingsIcon } from './icons/SettingsIcon.svg';
import { ReactComponent as LSMDashboardIcon } from './icons/LSMDashboardIcon.svg';
import { ReactComponent as InvestmentGuidelinesIcon } from './icons/InvestmentGuidelinesIcon.svg';
import { ReactComponent as CleanStorePolicyIcon } from './icons/CleanStorePolicyIcon.svg';
import { ReactComponent as AdvertisingAndSignage } from './icons/AdvertisingAndSignage.svg';
import { ReactComponent as BrandAssetsIcon } from './icons/BrandAssetsIcon.svg';
import { ReactComponent as ProductImagesIcon } from './icons/ProductImagesIcon.svg';
import { ReactComponent as BriefingTemplatesIcon } from './icons/BriefingTemplatesIcon.svg';
import { ReactComponent as EmailSettingsIcon } from './icons/EmailSettingsIcon.svg';
import { ReactComponent as KeyContactsIcon } from './icons/KeyContactsIcon.svg';
import { ReactComponent as SettingUsers } from './icons/SettingUsers.svg';
import { ReactComponent as GeneralSettingsIcon } from './icons/GeneralSettingsIcon.svg';
import { ReactComponent as SettingRequestLogin } from './icons/settingRequestLogin.svg';
import { ReactComponent as BackArrowIcon } from "./icons/BackArrowIcon.svg";
import { ReactComponent as NextArrowIcon } from './icons/NextArrowIcon.svg';
import { ReactComponent as RecruitmentIcon } from './icons/RecruitmentIcon.svg';
import { USER_TYPE } from '../../defs/User';
import { useQuery } from '@apollo/client';
import GQL from '../../gql';

const IconComponents = {
    DashboardIcon: lazy(() => import('./icons/DashboardIcon.svg').then(module => ({ default: module.ReactComponent }))),
    SearchIcon: lazy(() => import('./icons/SearchIcon.svg').then(module => ({ default: module.ReactComponent }))),
    PodsIcon: lazy(() => import('./icons/PodsIcon.svg').then(module => ({ default: module.ReactComponent }))),
    LSMIcon: lazy(() => import('./icons/LSMIcon.svg').then(module => ({ default: module.ReactComponent }))),
    AddEditStoresIcon: lazy(() => import('./icons/AddEditStoresIcon.svg').then(module => ({ default: module.ReactComponent }))),
    SettingsIcon: lazy(() => import('./icons/SettingsIcon.svg').then(module => ({ default: module.ReactComponent }))),
    LSMDashboardIcon: lazy(() => import('./icons/LSMDashboardIcon.svg').then(module => ({ default: module.ReactComponent }))),
    InvestmentGuidelinesIcon: lazy(() => import('./icons/InvestmentGuidelinesIcon.svg').then(module => ({ default: module.ReactComponent }))),
    CleanStorePolicyIcon: lazy(() => import('./icons/CleanStorePolicyIcon.svg').then(module => ({ default: module.ReactComponent }))),
    AdvertisingAndSignage: lazy(() => import('./icons/AdvertisingAndSignage.svg').then(module => ({ default: module.ReactComponent }))),
    BrandAssetsIcon: lazy(() => import('./icons/BrandAssetsIcon.svg').then(module => ({ default: module.ReactComponent }))),
    ProductImagesIcon: lazy(() => import('./icons/ProductImagesIcon.svg').then(module => ({ default: module.ReactComponent }))),
    BriefingTemplatesIcon: lazy(() => import('./icons/BriefingTemplatesIcon.svg').then(module => ({ default: module.ReactComponent }))),
    EmailSettingsIcon: lazy(() => import('./icons/EmailSettingsIcon.svg').then(module => ({ default: module.ReactComponent }))),
    KeyContactsIcon: lazy(() => import('./icons/KeyContactsIcon.svg').then(module => ({ default: module.ReactComponent }))),
    SettingUsers: lazy(() => import('./icons/SettingUsers.svg').then(module => ({ default: module.ReactComponent }))),
    GeneralSettingsIcon: lazy(() => import('./icons/GeneralSettingsIcon.svg').then(module => ({ default: module.ReactComponent }))),
    SettingRequestLogin: lazy(() => import('./icons/settingRequestLogin.svg').then(module => ({ default: module.ReactComponent }))),
    BackArrowIcon: lazy(() => import('./icons/BackArrowIcon.svg').then(module => ({ default: module.ReactComponent }))),
    NextArrowIcon: lazy(() => import('./icons/NextArrowIcon.svg').then(module => ({ default: module.ReactComponent }))),
    RecruitmentIcon: lazy(() => import('./icons/RecruitmentIcon.svg').then(module => ({ default: module.ReactComponent }))),
  };

const renderIcon = (iconName, props) => {
    if (!iconName) return null;

    const IconComponent = IconComponents[iconName];
    return IconComponent ? <IconComponent {...props} /> : null;
};

let _menuStack = [];
  
function Sidebar() {
    const { user } = useContext(AuthContext);

    // const [childNavMenus, setChildNavMenus] = useState([]);
    // const [menuStack, setMenuStack] = useState([]);
    const [firstClick, setFirstClick] = useState(true);

    let lrmMenu = () => {
        let parentSidebar = document.querySelector('.sidebar-parent');
        let lsmSidebar = document.querySelector('.sidebar-lrm');
        parentSidebar.classList.add('disable');
        lsmSidebar.classList.add('active');
        document.querySelector('.sidebar').style.height = '800px';
        // setMenuStack((stack) => {
        //     return [...stack,lsmSidebar];
        // });
        console.log('>>_menuStack length',_menuStack.length);
        if (firstClick && !_menuStack.length) {
            initInitialMenuStack();
            setFirstClick(false);
        }
        _menuStack.push(lsmSidebar);
    }

    let settingsMenu = () => {
        let parentSidebar = document.querySelector('.sidebar-parent');
        let settingsMenu = document.querySelector('.sidebar-settings');
        parentSidebar.classList.add('disable');
        settingsMenu.classList.add('active')
    }

    let advertisingAndSignageMenu = () => {
        let lsmSidebar = document.querySelector('.sidebar-lrm');
        let advertisingAndSignageSidebar = document.querySelector('.sidebar-lrm-advertising-and-signage');
        lsmSidebar.classList.remove('active');
        advertisingAndSignageSidebar.classList.add('active');
        document.querySelector('.sidebar').style.height = '800px';
    }

    let productImagesMenu = () => {
        let lsmSidebar = document.querySelector('.sidebar-lrm');
        let productImagesSidebar = document.querySelector('.sidebar-lrm-product-images');
        lsmSidebar.classList.remove('active');
        productImagesSidebar.classList.add('active')
    }

    let backToMainMenu = (e) => {
        let parentSidebar = document.querySelector('.sidebar-parent');
        let activeMenu = e.target.closest('nav');
        parentSidebar.classList.remove('disable');
        activeMenu.classList.remove('active')
    }

    let backToLRMMenu = (e) => {
        let lsmSidebar = document.querySelector('.sidebar-lrm');
        let activeMenu = e.target.closest('nav');
        lsmSidebar.classList.add('active');
        activeMenu.classList.remove('active')
    }

    const doMenuBack = (e) => {
        console.log('>doMenuBack doMenuBack');
        console.log('>doMenuBack',_menuStack,_menuStack.length);

        const length = _menuStack.length;
        const last = _menuStack[length-1]?.classList;
        const beforeLast = _menuStack[length-2]?.classList;
        
        console.log('>doMenuBack last',last);
        console.log('>doMenuBack beforeLast',beforeLast);

        last.add('disable');
        last.remove('active');
        
        beforeLast?.add('active');
        beforeLast?.remove('disable');

        _menuStack.pop();
    }

    const { loading, error, data, refetch } = useQuery
    (
        GQL.Category.GET_CATEGORY_TREE,
        {
            variables: { parentId: '63b3bc974f384b91692ac132'}
        }
    );
    // const LRMRoot = data?.getCategoryTree;
    // const renderNavListItem = (navItem) => {
    //     const doOpen = () => {
    //         let parentSidebar = document.querySelector('.sidebar-parent');
    //         let safeName = navItem.uri.split('/')[0];
    //         console.log('safeName',safeName);
    //         if (navItem.children.length) {
    //             let sidebar = document.querySelector(`.sidebar-${safeName}`);
    //             parentSidebar.classList.add('disable');
    //             sidebar.classList.add('active');
    //             document.querySelector('.sidebar').style.height = '650px';
    //         }
    //     }

    //     return (
    //         <NavItem to={`/${navItem.uri}`} open={doOpen} key={navItem.uri}>
    //             {/* <LSMIcon /> */}
    //             {navItem.icon && (renderIcon(navItem.icon))}
    //             {navItem.name}
    //             {navItem.children.length && (
    //                 <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
    //             )}
    //         </NavItem>
    //     );
    // }

    const memoNavMenus = useMemo(() => {
        const menus = [];
        const LRMRoot = data?.getCategoryTree;

        const role = user?.userRole;
        // const role = 3;
        
        const renderNavList = (navList, parent, baseURI, renderBackButton, status) => {
            if (!navList) {
                return <></>;
            }
            let safeParentName = parent?.uri.split('/')[0];

            return (
                <NavList status={status} parent={safeParentName} key={parent.uri} disabled={status}>
                    {renderBackButton && (
                        <NavItem open={doMenuBack} to={'/'}>
                            <BackArrowIcon />
                            Back
                        </NavItem>
                    )}
                    {navList.map(childNavItem => (
                        renderNavListItem(childNavItem,parent,baseURI)
                    ))}
                </NavList>
            );
        }

        const renderNavListItem = (navItem,parent,baseURI) => {
            const doOpen = () => {
                if (navItem.children.length) {
                    let safeName = navItem.uri.split('/')[0];
                
                    console.log('>>_menuStack length',_menuStack.length);
                    if (firstClick && !_menuStack.length) {
                        initInitialMenuStack();
                        setFirstClick(false);
                    }

                    let sidebar = document.querySelector(`.sidebar-${safeName}`);
                    console.log('>>_menuStack add',sidebar);

                    _menuStack.push(sidebar);

                    const length = _menuStack.length;
                    const last = _menuStack[length-1]?.classList;
                    const beforeLast = _menuStack[length-2]?.classList;
                    
                    last?.add('active');
                    beforeLast?.remove('active');

                    console.log('>>_menuStacks state',_menuStack);
                }
                return null;
            }

            let navTo = `${baseURI}/${navItem.uri}`;
            console.log('navTo',navTo);

            if (navItem.children?.length) {
                menus.push(renderNavList(
                    navItem.children,   //navList
                    navItem,            //parent
                    navTo,              //baseURI
                    true,               //renderBackButton
                    false               //status
                ))
            }
    
            return (
                <NavItem to={`/${navTo}`} open={navItem.children?.length?doOpen:null} key={navItem.uri}>
                    {/* <LSMIcon /> */}
                    {navItem.icon?
                    (renderIcon(navItem.icon))
                    :(<BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />)}
                    {navItem.name}
                    {!!navItem.children?.length && (
                        <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                    )}
                </NavItem>
            );
        }
        menus.push(renderNavList(
            LRMRoot?.children,              //navList
            LRMRoot,                        //parent
            LRMRoot?.uri,                   //baseURI
            (role !== USER_TYPE.NOROLE && role !== USER_TYPE.BUSINESS_OWNER),    //renderBackButton
            (role === USER_TYPE.NOROLE || role === USER_TYPE.BUSINESS_OWNER)     //status
        ));
        return menus;
    },[data]);

    const initInitialMenuStack = () => {
        console.log('>>_menuStack add',document.querySelector(`.sidebar-parent`));
        console.log('>>_menuStack. add',document.querySelector(`.sidebar-lrm`));
        
        const parent = document.querySelector(`.sidebar-parent`);
        _menuStack = []
        if (parent) {
            _menuStack.push(parent);
        

            const role = user?.userRole;
            // const role = 3;
    
            if (role === USER_TYPE.NOROLE
            || role === USER_TYPE.BUSINESS_OWNER
            ) {
                const lrm = document.querySelector(`.sidebar-lrm`);
                if (lrm) {
                    _menuStack.push(document.querySelector(`.sidebar-lrm`));
                }
            }
        }

        console.log('>>_menuStacks state',_menuStack);
    }

    useEffect(initInitialMenuStack,[]);

    if (loading)
    {
        return <div>Loading...</div>;
    }
    if (error)
    {
        return <div>Error! ${error.message}</div>;
    }

    if (user) {

        // const role = 3;

        switch (user.userRole) {
        // switch (role) {
            case USER_TYPE.DEVELOPER:
            case USER_TYPE.ADMIN: return (
                <Suspense fallback={<p>Loading...</p>}>
                    <div className='sidebar'>
                        <NavList>
                            <NavItem to={'/'}>
                                <DashboardIcon />
                                Dashboard
                            </NavItem>
                            <NavItem open={lrmMenu}>
                                <LSMIcon />
                                LRM
                                <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                            </NavItem>
                            <NavItem to={'/stores'}>
                                <AddEditStoresIcon />
                                Stores
                            </NavItem>
                            <NavItem to={'/categories'}>
                                <BrandAssetsIcon />
                                Categories
                            </NavItem>
                            <NavItem open={settingsMenu} to={'/settings/general'}>
                                <SettingsIcon />
                                Settings
                                <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                            </NavItem>
                        </NavList>
                        {memoNavMenus}
                        <NavList parent={'settings'}>
                            <NavItem open={backToMainMenu} to={'/lrm'}>
                                <BackArrowIcon />
                                Main Menu
                            </NavItem>
                            <NavItem to={'/settings/general'}>
                                <GeneralSettingsIcon />
                                General
                            </NavItem>
                            <NavItem to={'/settings/users'}>
                                <SettingUsers />
                                Users
                            </NavItem>
                            <NavItem to={'/settings/request-login'}>
                                <SettingRequestLogin />
                                Request Login
                            </NavItem>
                            <NavItem to={'/settings/email'}>
                                <EmailSettingsIcon />
                                Email
                            </NavItem>
                            <NavItem to={'/settings/assets'}>
                                <GeneralSettingsIcon />
                                Assets
                            </NavItem>
                        </NavList>
                    </div>
                </Suspense>
            )
            case USER_TYPE.MARKETER: return (
                <Suspense fallback={<p>Loading...</p>}>
                    <div className='sidebar'>
                        <NavList>
                            <NavItem to={'/'}>
                                <DashboardIcon />
                                Dashboard
                            </NavItem>
                            <NavItem to={'/search'}>
                                <SearchIcon />
                                Search
                            </NavItem>
                            <NavItem to={'/pods'}>
                                <PodsIcon />
                                Pods
                            </NavItem>
                            <NavItem open={lrmMenu}>
                                <LSMIcon />
                                LRM
                                <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                            </NavItem>
                        </NavList>
                        {memoNavMenus}
                    </div>
                </Suspense>
            )
            case USER_TYPE.BUSINESS_OWNER:
            default: return (
                <Suspense fallback={<p>Loading...</p>}>
                    <div>
                        <div className='sidebar sidebar-xdashboard'>
                            <NavList>
                                <NavItem to={'/'} status={true}>
                                    <DashboardIcon />
                                    Dashboard
                                </NavItem>
                            </NavList>
                        </div>
                        <div className='sidebar'>
                            {memoNavMenus}
                        </div>
                    </div>
                </Suspense>
            )
        }

        return (
            <div className='sidebar'>
                {/* <NavList>
                    {renderNavListItem(LRMRoot)}
                </NavList> */}
            </div>
        );
    } else {
        return null
    }
}

export default Sidebar