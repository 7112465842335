import { useMutation, gql } from '@apollo/client';
import { toast } from 'react-toastify';

import { TabTitle } from "../../../utils/TabTitle";
import { useForm } from '../../../hooks/useForm';
import RequestLoginBanner from "../../../components/RequestLoginBanner/RequestLoginBanner";
import ForgotCredentials from "../../../components/ForgotCredentials/ForgotCredentials";
import './ForgotPassword.scss';

function ForgotPassword({title}) {
    TabTitle(title  || 'KFC LRM');

    const { onChange, onSubmit, values } = useForm(forgotPasswordCallback, {
        email: '',
        username: ''
    });

    const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
        update(_, { data: { forgotPassword: userData } }) {
            if (userData) {
                toast.success("A link to restore access has been sent to an email", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: 'forgot-password-success'
                });
                toast.info("Check your email address", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: 'forgot-password-info'
                });
            }
        },
        onError(err) {
            let errMsg = err.graphQLErrors[0].message;

            toast.error(errMsg, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: 'forgot-password-error'
            });
        },
        variables: values
    });

    async function forgotPasswordCallback() {
        await forgotPassword();
    }

    return (
        <div className={'forgot-password'}>
            <form onSubmit={onSubmit} className={'forgot-password__form'}>
                <RequestLoginBanner />
                <h3 style={{marginBottom: '21px'}}>Forgot Password</h3>
                <div className={'forgot-password__form__row'}>
                    <input type={'email'} id={'email'} name={'email'} placeholder={'Add Email'} value={values.email} onChange={onChange} />
                    <span>OR</span>
                    <input type={'text'} id={'username'} name={'username'} placeholder={'Username'} value={values.username} onChange={onChange} />
                </div>
                <button type={'submit'}>Enter</button>
                <ForgotCredentials />
            </form>
        </div>
    )
}

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!, $username: String!) {
    forgotPassword(email: $email, username: $username)
  }
`;

export default ForgotPassword